.page-container.public-profile {
    @include respond-to(small) {
        margin-top: 0;
        width: 100%;
        padding: 0px 10px;
    }
}

.page-container.public-profile .page-left {
    position: relative;
    @include respond-to(small) {
        width: 100%;
    }
}
.profile-left-header {
    margin-top: 10px;
    padding-right: 20px;
    @include respond-to(small) {
        width: 100%;
        margin-top: 10px;
    }
}

.profile-left-header .header-group {
    @include flex-start;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
    padding-left: 10px;
}

.profile-left-header .user-name-group {
    margin-left: 20px;

    p {
        font-size: 14px;
        color: #666;
    }
}
.profile-left-header .add-friend-icon {
    margin-left: auto;
    font-size: 16px;
    column-gap: 5px;
}





// .user-follows {
//     margin: 10px 0;
// }

.user-follows .user-item {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.user-follows .user-info {
    flex: 1;
    margin-left: 10px;
}

.user-follows .user-name {
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
    color: $secondary-font-color;
}

.user-follows .user-username {
    color: #666;
    font-size: 14px;
}

.user-follows .follow-button, .friends-button {
    padding: 6px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  
.user-follows .follow-button {
    background-color: $secondary-color;
    color: white;
}
  
.user-follows .friends-button {
    background-color: #e0e0e0;
    color: #333;
}

.user-follows .show-all {
    font-size: 14px;
    color: #666;
    cursor: pointer;
    margin: 10px 0;
}