// 基础变量定义
$text-color: #4a4a4a;
$link-color: #257953;
$bg-light: #f5f5f5;
$border-light: #e0e0e0;

// 基础文本样式
@mixin base-text {
    font-size: 16px;
    font-weight: 400;
    color: $text-color;
    margin-bottom: 20px;
}

.article-reader {
    max-width: 800px;
    width: 100%;
    height: calc(100vh - 180px);
    margin: 0 auto;
    padding: 20px 20px 0px 20px;
    line-height: 150%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
    scroll-behavior: smooth;
    position: relative;
    overscroll-behavior: none;
    
    will-change: scroll-position;
    overflow-x: hidden !important;

    border: 2px solid #f2f3f4;
    border-radius: 10px;

    @include respond-to(large) {
        width: 100%;
        height: fit-content;
        padding: 0px;
        border: none;
    }

    // Scrollbar styles
    &::-webkit-scrollbar {
        width: 8px;
        
        &-track {
            background: transparent;
        }
        
        &-thumb {
            background-color: rgba(155, 155, 155, 0.5);
            border-radius: 4px;
            
            &:hover {
                background-color: rgba(155, 155, 155, 0.7);
            }
        }
    }

    // Hidden elements
    button, .modal, .speechify-ignore {
        display: none !important;
    }

    // Typography
    h1, h2, h3, h4, p, ul, ol {
        @include base-text;
    }

    h1, h2, h3, h4 {
        font-weight: 600;
    }

    h1 { font-size: 24px; }
    h2 { font-size: 20px; }
    h3 { font-size: 18px; }
    h4 { font-size: 16px; }

    // Lists
    ul, ol {
        margin-left: 20px;
    }

    // Links
    a {
        color: $link-color;
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }

    // Content elements
    blockquote, pre {
        @include base-text;
        padding: 10px 20px;
        background: $bg-light;
        border-left: 5px solid $link-color;
    }

    pre {
        max-width: 100%;
        overflow-x: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    code {
        @include base-text;
        background: $bg-light;
        padding: 2px 5px;
        border-radius: 5px;
    }

    // Table styles
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        overflow-x: auto;
        display: block;

        th, td {
            @include base-text;
            padding: 10px;
            border: 1px solid $border-light;
        }

        th {
            font-weight: 600;
            background: $bg-light;
        }

        tr {
            &:nth-child(even) { background: $bg-light; }
            &:nth-child(odd) { background: #ffffff; }
            &:hover { background: #f0f0f0; }
        }
    }
}

.article-reader #articleContent {
    height: fit-content;
    overflow: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

.article-reader div {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

.article-reader .print-wrapper {
    margin: 0px 10px;
}

article #gaDataLength {
    display: none !important;
}

.article-reader #gaDataAuthors {
    font-size: 16px;
    font-weight: 400;
    color: #4a4a4a;
    margin-bottom: 20px;
}

    .ip.iq.ir.is.it.ab div{
        display: inline-flex;
        align-items: flex-end;
        column-gap: 10px;
    }

    .article-reader p {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        margin-bottom: 20px;
        text-align: left;
    }
    .article-reader ul {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        margin-bottom: 20px;
        margin-left: 20px;
    }
    .article-reader ol {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .article-reader blockquote {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        margin-bottom: 20px;
        padding: 10px 20px;
        background: #f5f5f5;
        border-left: 5px solid #257953;
    }
    .article-reader pre {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        margin-bottom: 20px;
        padding: 10px 20px;
        background: #f5f5f5;
        max-width: 100%;
        overflow-x: auto;
        white-space: pre;
        word-wrap: normal;
        display: block;
    }
    .article-reader code {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        background: #f5f5f5;
        padding: 2px 5px;
        border-radius: 5px;
        max-width: 100%;
        display: inline-block;
        overflow-x: auto;
        vertical-align: middle;
    }
    .article-reader table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        max-width: 100%;
        overflow-x: auto;
        display: block;
    }
    .article-reader table th {
        font-size: 16px;
        font-weight: 600;
        color: #4a4a4a;
        background: #f5f5f5;
        padding: 10px;
        border: 1px solid #e0e0e0;
    }
    .article-reader table td {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        padding: 10px;
        border: 1px solid #e0e0e0;
    }
    .article-reader table tr:nth-child(even) {
        background: #f5f5f5;
    }
    .article-reader table tr:nth-child(odd) {
        background: #ffffff;
    }
    .article-reader table tr:hover {
        background: #f0f0f0;
    }
    .article-reader table a {
        font-size: 16px;
        font-weight: 400;
        color: #257953;
        text-decoration: none;
    }
    .article-reader table a:hover {
        text-decoration: underline;
    }

    .article-reader table blockquote {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        margin-bottom: 20px;
        padding: 10px 20px;
        background: #f5f5f5;
        border-left: 5px solid #257953;
    }
    

    .article-header {
        margin-bottom: 40px;
        width: 100%;
        text-align: left;
        align-self: flex-start;
    }
    
    .article-title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 16px;
        line-height: 1.2;
    }
    
    .article-meta {
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 16px;
        display: flex !important;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 8px;
        align-items: center;
        width: 100%;
    }
    
    .article-meta span {
        display: inline;
        white-space: nowrap;
    }
    
    .article-excerpt {
        font-size: 1.1rem;
        color: #444;
        line-height: 1.6;
        margin-top: 20px;
        padding: 16px;
        background: #f8f9fa;
        border-radius: 8px;
    }
    
    .article-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.1rem;
        line-height: 1.8;
        color: #2c3e50;
        position: relative;
        padding-top: 0;
        margin-top: 0;
        
        img {
            display: block;
            max-width: 100%;
            height: auto;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            margin: 0 auto;
            cursor: zoom-in !important;
        }
    }
    
    /* Handle Medium-style image layouts */
    .article-content figure[class*="w-richtext-align-"] {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .article-content figure[class*="w-richtext-align-fullwidth"] {
        width: 100vw;
        max-width: 100vw;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }
    
    .article-content p {
        width: 100%;
        max-width: 100%;
        text-align: left;
    }
    
    /* Force block display for certain elements to ensure they take their own line */
    .article-content p,
    .article-content .captioned-image-container,
    .article-content blockquote,
    .article-content ul,
    .article-content ol {
        display: block;
        width: 100%;
        clear: both;
    }
    
    /* Add specific spacing for headers */
    .article-content h1 {
        display: block;
        width: 100%;
        clear: both;
        margin-bottom: 0.2em;
        line-height: 1.4;
    }
    
    .article-content h2,
    .article-content h3,
    .article-content h4,
    .article-content h5,
    .article-content h6 {
        display: block;
        width: 100%;
        clear: both;
        margin-top: 0.2em;
        margin-bottom: 1em;
        line-height: 1.4;
    }
    
    /* Add styles for lists */
    .article-content ul,
    .article-content ol {
        padding-left: 1em;
        margin-bottom: 1.5em;
    }
    
    .article-content ul li p,
    .article-content ol li p {
        margin-bottom: 0.5em;
        line-height: 1.6;
    }
    
    .article-content ul {
        list-style-type: disc;
    }
    
    .article-content ol {
        list-style-type: decimal;
    }
    
    /* Add Modal Styles */
    .image-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        cursor: pointer;

        img {
            max-width: 90vw;
            max-height: 90vh;
            object-fit: contain;
            cursor: zoom-out !important;  // Keep zoom-out cursor for modal image
        }
    }

    // 确保内容容器的稳定性
    .article-content {
        min-height: 100vh;
        width: 100%;
        position: relative;
        contain: layout;
    }

