
/* purgecss start ignore */
@import 'variables';
@import 'mixins';

.title-one {
    color: $primary-color;
    font-size: $primary-title-size;
    font-weight: 600;
    @include flex-Vcenter;
    justify-content: flex-start;
}

.title-two {
    color: $secondary-font-color;
    font-size: $secondary-title-size;
    font-weight: 600;
    @include flex-Vcenter;
    justify-content: flex-start;
}

.title-three {
  color: $secondary-font-color;
  font-size: 16px;
  font-weight: 500;
}

/* purgecss end ignore */