.login {
    width: 100%;
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 200px;
    margin: 0;
}
    @media screen and (max-width: 670px){
        .login {
            padding: 10px 20px;
        }
    }

.login-form-container {
    height: fit-content;
    width: 620px;    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-self: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: $card-border-radius;
    padding: 30px 40px;
}
@media screen and (max-width: 670px) {
    .login-form-container {
        width: 100%;
        padding: 30px 20px;
    }
    
}

.login-form {
    background-color: #ffffff;
    width: 100%;
}

.welcome-back {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #48c78e;
}
.welcome-back.thank-you {
    width: 100%;
    text-align: center;
}

.login-form-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.google-login-container {
    width: 100%;
    margin-top: 20px;
}

.login-form-group {
    display: flex;
    inline-size: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.password-input {
    width: 100%;
    position: relative;
    display: inline-block;
}
.password-visibility-icon {
    font-size: large;
    position: absolute;
    right: 10px;
    top: 50%;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    cursor: pointer;
    color: #9c9c9c;
}

.login-form-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    width: 100%;
}
.login-form-label.will-send-email {
    text-align: center;
}

.login-form-control {
    width: 100%;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 4px;
    padding: 10px;
    align-items: flex-start;
    font-size: 16px;
}

.user-name-enter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

.password-requirement {
    font-size: 12px;
    font-weight: 300;
    color: #555555;
    margin-top: 5px;
}
.requirement-met {
    color: #257953;
}
.requirement-not-met {
    color: #FB4242;
}

.warning {
    font-size: 12px;
    color: rgb(252, 76, 76);
    margin-bottom: 5px;
}



.login-form-footer {
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
    position: relative;
}
    @media screen and (max-width: 670px){
        .login-form-footer {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 0px;
        }
    }

.login-form-footer-right {
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    justify-self: flex-end;
    width: fit-content;
    position: relative;
}
    @media screen and (max-width: 670px){
        .login-form-footer-right {
            height: fit-content;
            flex-direction: column;
        }
}    

.login-button {
    background-color: #48C78E;
    border: none;
    height: 35px;
    width: fit-content;
    border-radius: $button-border-radius;
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    padding: 4px 8px;
}

.login-form-link {
    display: flex;
    flex-direction: column;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 20px;
}
    @media screen and (max-width: 670px){
        .login-form-link {
            margin-right: 0px;
            align-items: center;
            margin-bottom: 10px;
        }
    }

.login-form-link.go-to-login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
    margin-top: 30px;
}

.login-form-link a {
    text-align: right;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
}

.login-form-link p {
    text-align: right;
    font-size: 14px;
    font-weight: 300;
    color: #555555;
    text-align: center;
}

/* Notices */

.login-status {
    height: 42px;
    max-width: 250px;
    display: flex;
    align-items: center;

    font-size: 14px;
    line-height: 21px;
    color: #257953;
    /* margin-bottom: 5px; */
    /* position: absolute;
    left: 0px;
    top: 0px; */
}
    @media screen and (max-width: 670px){
        .login-status {
            height: fit-content;
            position: inherit;
            text-align: center;
            margin-bottom: 8px;
        }
    }
.login-status.error-notice {
    color: rgb(224, 46, 46);

}

/* Activation page */

.activation-status {
    max-width: 350px;
}

/* Forget password */
.request-status {
    font-size: 12px;
    color: #485fc7;
    position: absolute;
    left: 0px;
}