.the-tabs {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px;
    // margin-top: 30px;

    @include respond-to (large) {
        width: 640px;
        height: fit-content;
        padding: 0px;
        margin-top: 10px;
    }

    @include respond-to (small) {
        width: 100%;
        padding: 0px;
    }
}

.the-tabs .tabs {
    width: fit-content;
    height: 40px;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 0 !important;
    background-color: #fff;
    position: relative;
    overflow: visible;

    @include respond-to (large) {
        height: 50px;
        overflow: visible;
        padding: 10px 15px;
        column-gap: 15px;
    }

    @include respond-to (small) {
        width: 100%;
        padding: 0px 10px;
        column-gap: 10px;
    }
}

    .the-tabs .tabs-wrapper {
        @include flex-Vcenter;
        justify-content: center;
        width: 620px;
        height: 50px;
        margin-bottom: 0 !important;
        background-color: #fff;
        position: sticky;
        top: 70px;
        transition: top 0.3s;
        overflow: visible;
        z-index: 96;

        @include respond-to (small) {
            width: 100%;
            position: sticky;
            top: 59px;
        }
    }


    .the-tabs .tabs .tab-button {
        @include flex-center;
        height: 30px;
        padding: 8px 4px;
        background-color: transparent;
        color: $tertiary-font-color;
        font-size: 18px;
        font-weight: 600;
        // border-bottom: 2px solid transparent;
        transition: all 0.2s ease;

        &:hover {
            color: $secondary-font-color;
        }

        @include respond-to (small) {
            padding: 8px 10px;
            font-size: 18px;
        }

        &.active {
            color: $primary-color;
            // border-bottom: 2px solid $primary-color;
        }
    }


    .new-feed-count {
        position: absolute;
        top: -5px;
        right: -10px;
        background: $badge-color;
        color: white;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // Shuffle Toggle
    .shuffle-toggle-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        margin-right: 10px;
        position: relative;
    }

    

.the-tabs .tab-content {
    @include flex-column;
    align-items: center;
    width: 100%;
    height: fit-content;
    overflow-x: visible;
    position: relative;
}

#tab-notes, #tab-resources, #tab-explore, #tab-library, #tab-revisit{
    width: 100%;
    height: fit-content;
    padding: 10px 0px 0px 0px;
    position: relative;
    margin-top: 0px;

    @include respond-to (small) {
        padding: 0 10px;
    }
}

.library-count {
    @include flex-center;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: $tertiary-font-color;
}

.feed-resource-divider {
    width: 100%;
    color: #257953;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 0 0;
    padding-bottom: 5px;
    @include respond-to(small) {
        height: 30px;
    }
}

.the-tabs hr {
    width: 100%;
    border-top: 1px solid #00000016;
    height: 1px; /* Change the height to 1px */
    margin: 15px 0; /* Add some margin for spacing */
}

.no-content-placeholder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #333;
    h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 200%;
        margin-bottom: 8px;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
    }
}

// Revisit Tab

.subject-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
.play-all-button {
    height: 30px;
    background: none;
    border: none;
    color: #4a4a4a;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
}

.play-all-button:hover {
    color: #2c2c2c;
}

// .play-all-button svg {
//     width: 13px;
//     height: 13px;
// }

.revisit-header {
    @include flex-start;
    width: 100%;
    min-height: 50px;
    height: fit-content;
    padding: 0 10px;
    @include respond-to(small) {
        min-height: 40px;
        height: fit-content;
        margin-top: 10px;
    }
}

.revisit-header .subject-title {
    height: fit-content;
    font-size: 18px;
    font-weight: 600;
    color: $primary-color;
    cursor: pointer;
}


.revisit-header .shuffle-button {
    margin-right: 10px;
    margin-left: auto;
    @include respond-to(small) {
        margin-right: 0;
    }
}

.play-all-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.play-all-button .loader {
  width: 24px;
  height: 24px;
}

.revisit-qa {
  margin: 0px 0px 15px 0px;
  width: 620px;
  padding: 15px 20px;
  background-color: #effaf5;
  border-radius: 8px;
  border-left: 4px solid #257953;
  @include respond-to(small) {
    width: 100%;
  }
}

.qa-question, .qa-answer {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
}

.qa-label {
  font-weight: 600;
  color: #257953;
  margin-right: 10px;
  min-width: 20px;
}

.qa-text {
  color: #4a4a4a;
  line-height: 1.5;
  font-size: 15px;
}

.qa-answer {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(37, 121, 83, 0.1);
}

// Revisit Tab Styles
.see-next-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0 50px 0;
}