.library-cards-container .note-card-wrapper, .library-cards-container .add-note-card {
    @media screen and (max-width: 2670px) {
        width: calc((100vw - 190px) / 4);
    }

    @media screen and (max-width: 2020px) {
        width: calc((100vw - 160px) / 3);
    }

    @media screen and (max-width: 1370px) {
        width: calc((100vw - 130px) / 2);
    }

    @media screen and (max-width: 640px) {
        width: 100%;
    }
}
.library-cards-container #resource-card {
    @media screen and (max-width: 2670px) {
        width: calc((100vw - 190px) / 4);
    }

    @media screen and (max-width: 2020px) {
        width: calc((100vw - 160px) / 3);
    }

    @media screen and (max-width: 1370px) {
        width: calc((100vw - 130px) / 2);
    }

    @media screen and (max-width: 900px) {
        width: 620px;
    }

    @media screen and (max-width: 740px) {
        width: 100%;
    }
}

.library-cards-container #resource-card .resource-card-upper-right {
    width: 93%;
} 