/* purgecss start ignore */

@import 'variables';


// Hover effect for all elements
.hover-to-primary-color {
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}

.hover-add-underline {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

// Quick responsive utility classes

.hide-large {
  @include respond-to (large) {
    display: none;
  }
}

.hide-medium {
  @include respond-to (medium) {
    display: none;
  }
}

.hide-small {
  @include respond-to (small) {
    display: none;
  }
}

// Hide scroll bar for all browsers
.hide-scrollbar {
  -ms-overflow-style: none; /* for IE and Edge */
  scrollbar-width: none; /* for Firefox */
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

// Divider

.rx-divider-vertical {
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
}
.rx-divider-horizontal {
  border-right: 1px solid #00000016;
  height: 100%;
}

// Margin Top Utility Classes
.mt-5 {
  margin-top: $margin-5 !important;
}
.mt-10 {
  margin-top: $margin-10;
}
.mt-15 {
  margin-top: $margin-15;
}
.mt-20 {
  margin-top: $margin-20;
}
// Margin Right Utility Classes
.mr-5 {
  margin-right: $margin-5;
}
.mr-10 {
  margin-right: $margin-10;
}
.mr-15 {
  margin-right: $margin-15;
}
.mr-20 {
  margin-right: $margin-20;
}
// Margin Bottom Utility Classes
.mb-5 {
  margin-bottom: $margin-5;
}
.mb-10 {
  margin-bottom: $margin-10;
}
.mb-15 {
  margin-bottom: $margin-15;
}
.mb-20 {
  margin-bottom: $margin-20;
}
// Margin Left Utility Classes
.ml-5 {
  margin-left: $margin-5;
}
.ml-10 {
  margin-left: $margin-10;
}
.ml-15 {
  margin-left: $margin-15;
}
.ml-20 {
  margin-left: $margin-20;
}

/* purgecss end ignore */