.note-card-wrapper {
    position: relative;
    width: 620px;
    height: auto;
    @include respond-to(small) {
        width: 100%;
        padding: 0px;
      }
}

/* Note card and inside */
.note-card {
    @include flex-column;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    min-height: 150px;
    position: relative;
    border-radius: $card-border-radius;
    background: #ffffff;
    border: 2px solid #f2f3f4;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
    .note-card-header {
        @include flex-space-between;
        align-items: flex-start;
        height: fit-content;
        width: 100%;
        padding: 24px 6px 0px 24px;
    }
    .note-card-content {
        width: 95%;
    }
   

    .note-content {
        @include flex-column;
        min-width: 97%;
        height: fit-content;
        justify-content: space-between;
        color: rgb(77, 77, 77);
    }
    .note-title {
        max-width: 100%;
        height: fit-content;
        color: #333333 !important;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 8px;
    }
        .editing-note-title {
            width: 100%;
            height: 24px;
            color: #343434;
            font-size: 18px;
            font-weight: 600;
            border: transparent;
            border-radius: $button-border-radius;
            background-color: transparent;
            margin-bottom: 8px;
            resize: none;
            margin-right: 20px;
            padding: 0px 2px 0px 2px;
        }
    .note-content-text {
        min-height: 50px;
        width: 100%;
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 5px;
        position: relative;

        p {
            margin: 10px 0 ;
        }
        
        ul, ol {
            padding-left: 10px;
            margin: 10px 0 10px 20px;
        }

        li {
            margin-bottom: 5px;
        }

        ul > li {
            list-style-type: disc;
        }

        ul > li > ul > li {
            list-style-type: circle;
        }

        ul > li > ul > li > ul > li {
            list-style-type: square;
        }

        ul > li > ul > li > ul > li > ul > li {
            list-style-type: square;
        }

        ul > li > ul > li > ul > li > ul > li > ul > li {
            list-style-type: square;
        }


        // Styles for ordered lists
        ol > li {
            list-style-type: decimal;
        }

        ol > li > ol > li {
            list-style-type: lower-alpha;
        }

        ol > li > ol > li > ol > li {
            list-style-type: lower-roman;
        }

        ol > li > ol > li > ol > li > ol > li {
            list-style-type: decimal;
        }

        ol > li > ol > li > ol > li > ol > li > ol > li {
            list-style-type: lower-alpha;
        }

        blockquote {
            border-left: 5px solid #eee;
            color: #666;
            font-style: italic;
            margin: 16px 0 !important;
            padding: 10px 20px !important;
        }

        blockquote p {
            margin: 0 !important;
        }

        h2 {
            font-size: 17px;
            margin: 16px 0 8px 0;
            font-weight: 600;
        }
            
    }
        @media (max-width: 1404px) {
            .note-content-text {
                max-height: fit-content;
            }
        }
    .note-content-text::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px; 
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        display: none;
        z-index: 93;
    }
    .note-content-text.overflowed::after {
        display: block;
    }

    .note-expand-button {
        @include flex-center;
        background-color: transparent;
        border: none;
        margin-right: auto;
        color: #636363;
    }
    .note-expand-button:hover {
        color: #257953;
    }
    .note-content-text::-webkit-scrollbar {
        width: 8px;
    }
    .note-content-text:hover::-webkit-scrollbar {
        width: 8px;
    }
    .note-content-text::-webkit-scrollbar-thumb {
        border-radius: 8px;
    }
    .note-content-text:hover::-webkit-scrollbar-thumb {
        background: #c4c4c4;
        border-radius: 8px;
    }
    .note-content-text::-webkit-scrollbar-thumb:hover {
        background: #a0a0a0;
    }
        .editing-note-content-text {
            max-width: 96%;
            text-align: left;
            line-height: 150%;
            overflow: auto;
            border: transparent;
            border-radius: $input-border-radius;
            background-color: transparent;
            font-size: 16px;
            font-weight: 400;
            resize: none;
            padding: 2px 2px 0px 2px;
            margin-right: 20px;
            margin-bottom: 3px;
        }

.note-last-edited {
    @include flex-end;
    align-items: center;
    width: 100%;
    color: #2e2e2e86;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    font-style: italic;
    margin-bottom: 5px;
}
    .note-last-edited .note-lock-icon{
        @include flex-center;
        height: 24px;
        width: 24px;
        padding: 6px;
        font-size: 12px;
        color: $tertiary-font-color;
    }


// Slim Note Card
.slim-note-card {
    display: flex;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 0px;
    cursor: pointer;
    border: 2px solid #f2f3f4;
}

.icon-container {
    flex-shrink: 0;
    margin-right: 12px;
}

.note-icon {
    font-size: 20px;
    color: #666;
}

.content-container {
    flex-grow: 1;
    overflow: hidden;
}

.resource-title {
    margin: 0 0 4px 0;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resource-content {
    font-size: 14px;
    line-height: 1.4;
    color: #333;
    max-height: 2.8em; /* 2 lines * 1.4 line-height */
    overflow: hidden;
}

.resource-content p {
    margin: 0;
}