.my-linko {
    height: 100%;
    width: 1600px;
    display: flex;
    justify-content: center;
    column-gap: 40px;
    
}
.home-page-left {
    // padding-left: 50px;
    @include respond-to(small) {
        width: 100%;
        // padding: 0px;
    }
}

// Home Page Right
.home-page-right {
    width: 380px;
    height: fit-content; 
    @include respond-to(large) {
        display: none;
    }
}

.your-subjects {
    display: none;
    @include respond-to(large) {
        @include flex-column;
        height: fit-content;
        width: 640px;
        row-gap: 20px;
        overflow: hidden;
        padding: 0 20px 0 10px;
        margin: 30px 0 10px 0;
    }
    @include respond-to(small) {
        width: 100%;
    }
}
.your-subjects .title-three {
    text-wrap: nowrap;
    width: fit-content;
}
.your-subjects .your-subjects-section {
    width: 100%;
    display: flex;
    column-gap: 10px;
    overflow: auto;
}

// Recently Added
.recently-added {
    margin: 10px 0px;
}
.recently-added .recently-added-resources {
    @include flex-column;
}
.recently-added .recently-added-resource {
    @include flex-start;
    column-gap: 10px;
    width: 100%;
    height: 60px;
    margin-bottom: 8px;
    border-radius: $button-border-radius;
    cursor: pointer;
    padding: 5px;
    overflow: visible;
    &:hover {
        background-color: $grey-hover;
    }
}
.recently-added .recently-added-resource .resource-type-icon {
    padding-top: 5px;
}
.recently-added .recently-added-resource .resource-title {
    @include single-line-ellipsis;
    width: 260px;
    font-size: $tertiary-title-size;
    font-weight: $primary-text-weight;
    color: $primary-font-color;
}
.recently-added .recently-added-resource .resource-author {
    @include single-line-ellipsis;
    width: 260px;
    font-size: $tertiary-text-size;
    margin-top: 3px;
}

.recently-added-resource .add-resource-icon {
    cursor: pointer;
    color: $tertiary-font-color;
    &:hover {
        color: $primary-color;
    }
}



.my-linko .recent-notes{
    min-width: 620px;
}

.graph-expand {
    width: fit-content;
    height: fit-content;
    position: absolute; /* Add this line to position the element absolutely */
    text-align: right;
    top: 0;
    right: 0;
}

.show-all-button {
    width: 620px;
    display: flex;
    justify-content: flex-end;
    color: #8a8a8a;
    text-decoration: underline;
    margin-top: 15px;
}

.page-container {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 5vw;
    padding: 0px 5%;
    @include respond-to(x-large) {
        column-gap: 20px;
        padding: 0px 20px;
    }
    @include respond-to(small) {
        width: 100vw;
        padding: 0px;
    }

    .home-page-right-wrapper {
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        width: 380px; // adjust this value based on your sidebar width
        
        &.sidebar-hidden {
            width: 0;
            opacity: 0;
            margin: 0;
            padding: 0;
        }
        
        &.sidebar-visible {
            width: 380px; // adjust this value based on your sidebar width
            opacity: 1;
        }
    }
}





