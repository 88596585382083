.book-tabs {
    width: 100%;
    display: flex;
    column-gap: 8px;
    margin-bottom: 10px;
    position: sticky;
    @media (max-width: 490px) {
        margin-left: 0;
    }
}

.book-tabs .tab-button {
    height: 24px;
    font-size: 16px;
    border-radius: $button-border-radius;
    background-color: #f3f3f3;
}

.book-tabs .tab-button.active {
    color: $primary-color;
    background-color: $tertiary-color;
}

#googleBookViewer {
    width: 100%;
    height: calc(100vh - 200px);
    position: relative;
    z-index: 88;
    background-color: #fff;
    @include respond-to(large) {
        height: calc(100vh - 30px);
        padding: 0 20px;
    }
}
#googleBookViewer iframe {
    .gb-button-bar {
        display: none;
    }
}
/* Hide the toolbar buttons */
#googleBookViewer .gbs-bvft {
    display: none !important;
}
#googleBookViewer table {
    display: none;
}
/* Hide the specific toolbar buttons by their class names */
#googleBookViewer .gb-button {
    display: none !important;
}

/* You can add more specific styles to target other buttons if needed */
#googleBookViewer .gb-next, 
#googleBookViewer .gb-prev,
#googleBookViewer .gb-toc {
    display: none !important;
}
