.modal-backdrop {
  @include flex-center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  }
  
  .modal-content {
    width: fit-content;
    min-width: 200px;
    max-width: 400px;
    height: fit-content;
    max-height: 90%;
    overflow-y: auto;
    padding: 20px;
    background: white;  
    border-radius: $card-border-radius;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    color: #636363;
    font-size: 16px;
  }
  .modal-content p {
    font-size: 16px;
    font-weight: 500;
    color:#636363;
  }
  
  .modal-content .modal-buttons{
    display: flex;
    justify-content: center;
    column-gap: 10px;
  }
  .modal-content .modal-buttons button {
    height: 30px;
    min-width: 75px;
    width: fit-content;
    margin: 10px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    border-radius: $button-border-radius;
    color: #636363;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
  }
  .modal-content .modal-buttons button.cancel {
    border: #f0f0f0 1px solid;
  }
    .modal-content .modal-buttons button.confirm {
      background: #fb4242;
      color: #fff;
      font-weight: 500;
    }

  