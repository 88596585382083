.RichEditor-root {
  background: #fff;
  font-size: 16px;
  color: #333333;
  line-height: 1.5;
}
.DraftEditor-root {
  min-height: 150px;
}
.RichEditor-root p {
  margin: 8px 0;
}
.RichEditor-root h2 {
  font-size: 17px;
  font-weight: bold;
  margin: 12px 0 8px 0;
}

.RichEditor-root i {
  font-style: italic;
}

.RichEditor-root a {
  color: #485FC7;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 300px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin: 8px 0;
}

h2 .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin: 0;
}

.RichEditor-root blockquote {
  border-left: 3px solid #eee;
  color: #666666;
  font-style: italic;
  margin: 8px 0;
  padding: 8px 15px;

  .public-DraftStyleDefault-block {
    margin: 8px 0;
    
    &:first-child {
      margin-top: 0;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    color: #333333;
  }

  [data-offset-key] span[style*="font-weight: bold"] {
    color: #333333;
    font-weight: 600 !important;
  }
}

.RichEditor-root blockquote .public-DraftStyleDefault-block {
  margin: 0;
}

.RichEditor-content ol .public-DraftStyleDefault-block, .RichEditor-content ul .public-DraftStyleDefault-block {
  margin: 8px 0;
}

.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: 10px 0 10px 10px !important;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.link-modal {
  background: #ffffff;
  width: 400px;
  padding: 24px;
  border-radius: 12px;
  animation: modalFadeIn 0.2s ease;
}

.link-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.link-modal-input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  color: $primary-font-color; 
  background: #f8f9f9;
  transition: all 0.2s ease;
  margin-bottom: 10px;
  border: 0px solid #e6e6e6;

  &:focus {
    outline: none;
  }
}

.link-modal-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 12px;
}

.link-modal-button {
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.link-modal-button-primary {
  background: #2563eb;
  color: white;
}

.link-modal-button-primary:hover {
  background: #1d4ed8;
  transform: translateY(-1px);
}

.link-modal-button-danger {
  background: #dc2626;
  color: white;
}

.link-modal-button-danger:hover {
  background: #b91c1c;
  transform: translateY(-1px);
}

.link-modal-button-secondary {
  background: #f3f4f6;
  color: #4b5563;
}

.link-modal-button-secondary:hover {
  background: #e5e7eb;
  transform: translateY(-1px);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.blockquote-wrapper {
  > div {
    margin: 8px 0;
  }
}