.joyride-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 10px;
}
.joyride-subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
}

.react-joyride__tooltip {
    box-shadow: none !important;
}
.react-joyride__tooltip div {
    padding: 0 !important;
}

.joyride-content {
    padding: -20px -10px !important;
}