.calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
  }
  
  .header {
    @include flex-space-between;
    padding: 10px;
  }
  
  .body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
  }
  
  .day-header {
    font-weight: bold;
  }
  
  .day {
    padding: 10px;
    border: 1px solid #ccc;
    position: relative;
  }
  
  .different-month {
    background-color: #f0f0f0;
  }
  
  .activity {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: green;
    margin: auto;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    opacity: 0.2; /* Default, will be overridden inline */
  }
  
  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  }
  
  .activity:hover .tooltip {
    visibility: visible;
  }
  
  .footer {
    padding: 10px;
    text-align: center;  }

  