@import '../../../Stylesheets/_variables.scss';

.setting-page {

  section {
    margin-bottom: 30px;
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
    color: $primary-font-color;
    margin-bottom: 15px;
  }

  .setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    border-radius: 5px;

    &:last-child {
      border-bottom: none;
    }

    &.clickable {
      cursor: pointer;
    }

    span {
      font-size: 16px;
    }

    .secondary-text {
      color: #999;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .chevron-icon {
      padding-top: 5px;
      color: #999;
    }

    &.profile-link {
      flex-wrap: wrap;
    }

    .link-container {
      display: flex;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
    }

    .link {
      color: $tertiary-font-color;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 670px) {
        margin-left: 0px;
        white-space: normal;
      }
    }

    .copy-icon {
      cursor: pointer;
      margin-top: 2px;
      padding-top: 2px;
      color: #999;

      &:hover {
        color: $primary-color;
      }
    }

  }

  .description {
    font-size: 14px;
    color: $tertiary-font-color;
    margin: 4px 0;
  }

  .toggle {
    align-items: flex-start;

    > div {
      flex-grow: 1;
    }
  }

  // New Toggle Styles
  .toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 0;
    gap: 20px;

    > div {
      flex: 1;
      min-width: 0;
    }

    li {
      list-style-type: disc;
      margin-left: 20px;
    }
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    flex-shrink: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .toggle-slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #E0E0E0;
      transition: .4s;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: #999;
        transition: .4s;
        border-radius: 50%;
      }
    }

    input:checked + .toggle-slider {
      background-color: #48c78e;

      &:before {
        transform: translateX(16px);
        background-color: white;
      }
    }

    .toggle-label {
      color: $secondary-font-color;
      font-size: 12px;
      font-weight: bold;

      &::before {
        color: $secondary-color;
      }
    }
  }

  .sub-setting {
    padding: 15px 0;
  }

}

// Modals
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;
}
  
  .name-change-modal {
    background-color: white;
    border-radius: 10px;
    width: 620px;
    max-width: 90%;
    padding: 20px;
  }
  
  .name-change-modal .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0;
    height: 30px;
  
    h2 {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
    }
  
    .close-button {
      background: none;
      border: none;
      font-size: 28px;
      cursor: pointer;
      color: #999;
    }
  }
  
  .modal-body {
    .input-group {
      margin-bottom: 20px;
  
      label {
        display: block;
        font-size: 12px;
        font-weight: 500;
        color: #666;
        margin-bottom: 5px;
      }
  
      input {
        width: 100%;
        height: 50px;
        padding: 10px;
        background-color: #f4f4f4;
        border: transparent;
        border-radius: 5px;
        font-size: 18px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
  
    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &.cancel-button {
        background-color: transparent;
        color: #666;
        margin-right: 10px;
  
        &:hover {
          background-color: #f0f0f0;
        }
      }
  
      &.save-button {
        background-color: #4CAF50;
        color: white;
  
        &:hover {
          background-color: #45a049;
        }
      }
    }
  }

  .name-change-modal .email {
    font-size: 16px;
    background-color: #f4f4f4;  
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }