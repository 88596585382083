/* purgecss start ignore */

$primary-color: #257953;
$secondary-color: #48c78e;
$tertiary-color: #effaf5;
$badge-color: #F27215;
$custom-color: #F27215;

$primary-font-color: #333333;
$secondary-font-color: #666666;
$tertiary-font-color: #999999;

$grey-background: #f0f0f0;

$primary-text-size: 16px;
$secondary-text-size: 14px;
$tertiary-text-size: 12px;
$large-text-size: 18px;

$primary-title-size: 24px;
$secondary-title-size: 20px;
$tertiary-title-size: 16px;

$primary-text-weight: 500;
$secondary-text-weight: 400;
$username-text-weight: 600;

// Border Variables
$card-border-radius: 10px;
$button-border-radius: 5px;
$input-border-radius: 5px;
$tooltip-border-radius: 5px;

// Box Shadow Variables
$card-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);


// Margin Variables
$margin-5: 5px;
$margin-10: 10px;
$margin-15: 15px;
$margin-20: 20px;


$grey-hover: #f3f3f3;
/* purgecss end ignore */