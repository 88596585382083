.add-modal.manual-input-modal {
    width: 480px;
    height: fit-content;
}
    @media (max-width: 670px) {
        .add-modal.manual-input-modal {
            width: 100%;
        }
    }

.manual-input-modal form {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.manual-input-group {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    position: relative;
}
.manual-input-group label {
    font-size: 16px;
    color: #636363;
    line-height: 24px;
    width: 50px;
    text-align: left;
    margin-right: 10px;
    margin-bottom: 5px;
}
.manual-input-group select, .manual-input-group input{
    height: 48px;
    width: 100%;
    border-radius: $button-border-radius;
    border: 1px solid #ccc;
    padding: 5px;
    color: #636363;
    font-size: 16px;
    line-height: 24px;
    padding: 13px 10px;
    background-color: #fff;
}
    .manual-input-group select:focus,
    .manual-input-group input:focus 
    {
        outline: none;
    }

    .manual-input-group input:focus label {
        color: #48c78e;
    }

    #input-months {
        width: 100%;
        height: 200px;
    }

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinners {
    -moz-appearance: textfield;
    appearance: textfield;
}

.year-select {
    width: 100%;
    height: 100px;
    border-radius: $input-border-radius;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 5px;
    color: #636363;
    font-size: 16px;
    line-height: 24px;
    padding: 13px 10px;
    overflow: scroll;
    position: absolute;
    top: 100%;
}