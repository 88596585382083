#resource-card {
    width: 620px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch; 
    justify-content: space-between;
    border-radius: $card-border-radius;
    background: #ffffff;
    padding: 15px 12px 12px 20px;
    line-height: 150%;
    border: 2px solid #f2f3f4;
    overflow: visible; 
    @include respond-to (small) {
        width: 100%;
    }

    .add-to-library-button {
        @include flex-center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 500;
        height: 25px;
        padding: 0px 5px;
        width: fit-content;
    }

    .MoreTabWLink {
        cursor: pointer;
        width: 20px;
        height: 20px;
        align-self: flex-start; 
        flex-shrink: 0;
        position: relative;
    }

    .card-action-dropdown {
        top: 100%;
        right: -20px;
    }
}
    #resource-card .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        column-gap: 0px;
        margin-left: -2px;
        color: $tertiary-font-color;
        box-shadow: none;

        .card-header-left {
            display: flex;
            align-items: center;
            column-gap: 10px;
            .resource-type-icon {
                margin-right: 3px;
                margin-top: 1px;
            }    
            .header-group {
                display: flex;
                align-items: center;
            }
        }

        .type-text {
            font-size: 12px;
            font-weight: 500;
            color: $tertiary-font-color;
            text-transform: uppercase;
        }
    }
    


    #resource-card .resource-type-icon {
        flex-shrink: 0;
        overflow: hidden;
        font-size: 24px; 
        align-self: flex-start; 
        margin-top: 3px;
        margin-right: 10px;
    }
    .resource-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
        .text-image-info {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
            .resource-title-author{
                height: fit-content;
                display: flex;
                flex-direction: column;
                row-gap: 15px;
                justify-content: flex-start;
                overflow: hidden; 
                margin-bottom: 10px;
            }
            .resource-info .resource-title, .no-hover{
                // max-width: 550px; 
                // flex-grow: 1;
                max-height: 54px; // Increase max-height to accommodate 2 lines
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                line-height: 150%;
                margin: 0; 
                font-size: 18px;
                font-weight: 600;
                color: #333333;
                cursor: pointer;
                white-space: wrap;
            }
                .resource-info .resource-title:hover {
                    color: #257953;
                }
                .resource-info .no-hover:hover {
                    color: #333333;
                    cursor: default;
                }
                    .resource-card-upper-right .subjects {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-self: flex-end;
                        flex-shrink: 0;
                        flex-wrap: wrap;
                        column-gap: 8px;
                        row-gap: 6px;
                    } 

            .author {
                height: fit-content;
                flex-shrink: 0;
                // min-width: 83px;
                vertical-align: middle;
                text-align: left;
                color: $secondary-font-color;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                display: inline-flex;
                column-gap: 5px;
                align-items: center;
                white-space: wrap;
                flex-wrap: wrap;
                max-width: 100%; 
            }

            #resource-card .note-card-subjects {
                margin-top: auto;
            }

        #resource-card .resource-image {
            width: 17%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-shrink: 0; 
            overflow: hidden; 
            margin-top: 5px;
            position: relative;
            img {
                object-fit: contain;
                padding: 5px;
            }
        }



// Resource Thumbnail Placeholder

.resource-thumbnail-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #ffffff 50%, #f5f5f5 50%);
    border: 1px solid #ddd;
    border-radius: 4px;
    position: relative;
}

.resource-thumbnail-placeholder-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.resource-thumbnail-placeholder .resource-type-icon {
    position: absolute;
    top: 50%;
    left: 33%;
    transform: translate(-50%, -50%);
    color: #ccc;
}


.slim-resource-card {
    height: fit-content;
    min-height: 45px;
    width: 100%;
    @include flex-start;
    align-items: center;
    justify-content: space-between; // Add this to push the icon to the right
    column-gap: 8px;
    padding: 5px 10px;
    border-radius: $button-border-radius;
    color: $secondary-font-color;
    font-weight: 500;
    transition: background-color 0.2s ease;

    .add-resource-icon {
        cursor: pointer;
        color: $tertiary-font-color;
        transition: color 0.2s ease;
        z-index: 1;

        &:hover {
            color: $primary-color;
        }

        &:hover + .slim-resource-card-content {
            background-color: transparent;
        }
    }

    &:hover .add-resource-icon:hover + .slim-resource-card-content {
        background-color: transparent;
    }
}

.slim-resource-card-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    transition: background-color 0.2s ease;
}

.slim-resource-card-title {
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
    margin-bottom: 2px;
    color: $primary-font-color !important;

    &:hover {
        color: $primary-color !important;
        cursor: pointer;
    }
}

.slim-resource-card-author {
    font-size: 12px; 
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
}


.learned-users-button {
    display: flex;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    cursor: pointer;
    height: 25px;
    padding: 0px;
    svg {
        stroke-width: 0.75;
    }
}

.learned-users-modal {
    width: 400px !important;
    max-width: 90% !important;
    row-gap: 10px !important;

    h4 {
        margin-bottom: 10px;
        font-size: 18px;
        color: $primary-font-color;
        font-weight: 600;
    }

    .modal-header {
        padding: 0px;
        height: fit-content;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-body {
        height: fit-content;
        max-height: 70vh;
        overflow-y: auto;
        width: 100%;
    }

    .user-section {
        margin-bottom: 10px;
        width: 100%;

        .user-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;

            .user-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
            }

            .user-name {
                cursor: pointer;
                font-weight: 600;
                padding: 2px 4px;
                color: $secondary-font-color;
                margin-left: 5px;

                &:hover {
                    color: $primary-color;
                }
            }

            button {
                margin-left: auto;
            }
        }
    }
}







