.note-for-export {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 10px;
    padding: 10px 0px;
    position: relative;
}

.export-note-actions {
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 24px;
    position: absolute;
    top: 10px;
    left: calc(50% + 220px);
}
.export-note-action {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    border-radius: 20px;
    background-color: #eeeeee;
    color: #636363;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    cursor: pointer;
}
.export-note-actions .link-copied {
    width: fit-content;
    color: #636363;
    font-size: 12px;
    font-weight: 500;
    transition: opacity 0.5s;
}
.export-note-action:hover {
    background-color: #effaf5;
    color: #257953;
}
#export-note-card {
    width: 400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 0px;
    border-radius: 15px;
    background: #effaf5;
    padding: 24px 12px 12px 12px;
    line-height: 16px;
    font-weight: 400;
    position: relative;
    z-index: 1;
}
    #export-note-card blockquote {
        margin: 8px 15px;
        padding: 0;
        border-left: 4px solid #dcdcdc;
        margin-left: 12px;
        padding-left: 12px;
        font-style: italic;
        color: #4d4d4d;
    }

#export-note-card .DraftEditor-root {
    line-height: 150%;
}

#export-note-card .username {
    font-size: 14px;
    font-weight: 500;
    color: #636363;
    margin-bottom: 6px;
    padding-left: 12px;
}
#export-note-card .time {
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    color: #636363;
    margin-bottom: 20px;
    padding-left: 12px;
}

#export-note-card .card-for-share {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 20px;
}

#export-note-card .note-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #4d4d4d;
    margin-bottom: 10px;
}
#export-note-card .note-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #4d4d4d;
    margin-bottom: 15px;
}
#export-note-card .subject {
    display: inline-flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
    margin-bottom: 15px;
}
#export-note-card .subject-item {
    height: 20px;
    width: fit-content;
    font-size: 12px;
    padding: 4px 8px 6px 8px;
    background-color: #effaf5;
    color: #257953;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#export-note-card hr {
    width: 100%;
    height: 1px;
    align-self: center;
    background-color: #eeeeee;
    margin-bottom: 12px;
}
#export-note-card .resource {
    display: inline-flex;
}
#export-note-card .resource-title {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #4d4d4d;
    margin-left: 4px;
}
#export-note-card .resource-author {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    font-style: italic;
    color: #4d4d4d;
    margin-left: 28px;
}


#export-note-card .logo-footer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
}
#export-note-card .logo-footer .brand {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 2px;
}
#export-note-card .logo-footer .logo {
    font-size: 24px;
    height: 20px;
    font-weight: 600;
    color: #48c78e !important;
    margin-bottom: 0% !important;
}
#export-note-card .logo-footer .slogan {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    color: #257953;
    margin-left: 2px;
    margin-top: 0px;
} 
#export-note-card .logo-footer .link {
    font-size: 12px;
    font-weight: 400;
    color: #636363;
    margin-right: 24px;
    align-self: center;
}

