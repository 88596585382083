.resource-progress{
    height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.slim-resource-card .resource-progress {
    height: 20px;
}

.note-count {
    flex-shrink: 0;
    min-width: 34px;
    text-align: left;
    vertical-align: middle;
    line-height: 14px;
    color: $secondary-font-color;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
}
.slim-resource-card .note-count {
    font-size: 12px;
    width: 50px;
    color: $tertiary-font-color;
}

.resource-progress .resource-check-icon {
    color: #257953;
    font-size: 14px;
    line-height: 14px;
}
.slim-resource-card .resource-progress .resource-check-icon {
    font-size: 12px;
}
.resource-progress .progress-pencil {
    color: #b3b3b3;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
}
.slim-resource-card .resource-progress .progress-pencil {
    font-size: 12px;
}
.resource-progress .progress-bar-wrapper {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-radius: 4px;
    cursor: pointer;
    padding: 4px;
}
.slim-resource-card .resource-progress .progress-bar-wrapper {
    padding: 0;
    margin-top: 2px;
}
.resource-progress .progress-bar-wrapper:hover {
    background: #f2f2f2;
}
.resource-progress .progress-bar {
    height: 10px;
    width: 160px;
    border: 1px solid #dedede80;
    border-radius: 7px;
    position: relative;
}
.slim-resource-card .resource-progress .progress-bar {
    height: 8px;
    width: 180px;
}
.resource-progress .progress-bar-wrapper:hover .progress-bar {
    background-color: #ffffff;
}
    // @media screen and (max-width: 670px){
    //     .resource-progress .progress-bar {
    //         width: 100%;
    //     }
    // }
.resource-progress .progress-bar .progress-bar-fill {
    height: 100%;
    background: $primary-color;
    border-radius: 7px;
    position: relative;
}
.resource-progress .progress-portion {
    font-size: 12px;
    color: $secondary-font-color;
}
.slim-resource-card .resource-progress .progress-portion {
    color: $tertiary-font-color;
}
.resource-progress .set-progress-form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.resource-progress .progress-input {
    width: 50px;
    height: 16px;
    border: none;
    border-radius: 7px;
    background: #f2f2f2;
    margin: 0 3px;
    color: #636363;
}
.slim-resource-card .resource-progress .progress-input {
    width: 40px;
    height: 14px;
    font-size: 12px;
    border-radius: 7px;
}
.resource-progress .progress-input:focus {
    outline: none;
}
.resource-progress p {
    font-size: 14px;
}
.slim-resource-card .resource-progress p {
    font-size: 12px;
}
.resource-progress button {
    background: none;
    border: none;
    color: #626262;
    font-size: 14px;
    cursor: pointer;
}
.slim-resource-card .resource-progress button {
    font-size: 12px;
}
.resource-progress button:hover {
    color: #257953;
}
.set-progress-form .check-finished {
    display: flex;
    align-items: center;
    color: #a3a3a3;
}
.set-progress-form .check-finished:hover {
    color: #257953;
}

.progress-drag-dot {
    width: 16px;
    height: 16px;
    background: $primary-color;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: grab;
    display: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.progress-drag-dot:active {
    cursor: grabbing;
}

.progress-bar-wrapper:hover .progress-drag-dot {
    display: block;
}

.progress-bar-wrapper.dragging .progress-drag-dot {
    display: block;
}

.progress-bar-wrapper.dragging {
    cursor: grabbing;
}