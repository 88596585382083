/* Search */


#search {
    @include flex-center;
    position: absolute;
    left: 160px;
    @include respond-to(medium) {
        left: 120px;
    }
}
#search-form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.control.has-icons-left {
    display: flex;
    align-items: center;
}
.search-icon {
    @include flex-center;
    height: 18px;
    width: 18px;
    color: $tertiary-font-color;
    @include respond-to(medium) {
        height: 20px;
        width: 20px;
    }
}
.full-search-input {
    border: 2px solid #f2f3f4;
    border-radius: 30px;
    padding-left: 10px;
    column-gap: 5px;
    height: 35px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}


#navbar-search-small {
    width: fit-content;
}
#navbar-search-small .search-icon {
    @include flex-center;
    height: 35px;
    width: 35px;
    padding: 6px;
    border-radius: $button-border-radius;
    background-color: #ebebeb;
    color: $tertiary-font-color;
    transform: none;
    cursor: pointer;
}

#navbar-search-small .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 65;
    display: none;
}

#navbar-search-small .search-overlay.visible {
    display: block;
}
#navbar-search-small .full-search-input {
    width: 100vw;
    height: 60px;
    position: absolute;
    top: -25px;
    left: -140px;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 0px;
    padding: 0 10px;
    z-index: 66;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#navbar-search-small .full-search-input .close-icon {
    font-size: 20px;
    color: #a5a5a5;
    cursor: pointer;
}

.full-search-type-area {
    width: 140px;
    border: none;
    outline: none; 
    border-radius: $input-border-radius;
    font-size: 14px;

    @include respond-to(medium) {
        width: 100vw;
        background-color: #fff;
    }
}

.full-search-results {
    @include flex-start;
    max-width: 460px;
    min-width: 460px;
    max-height: 500px;
    height: fit-content;
    overflow: auto;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-radius: $card-border-radius;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 0 10px 10px 20px;
    z-index: 98;

    @include respond-to(medium) {
        width: 100vw;
        max-width: 100vw;
        min-width: 100vw;
        max-height: 100vh;
        border-radius: 0px;
        left: -140px;
        top: 35px;
    }
}
        
    
.full-search-results > div {
    position: relative;
}    

.full-search-results-title {
    width: 100%;
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 600;
    color: $secondary-font-color;
    background-color: #fff;
    color: $primary-color;
    position: sticky;
    top: 0;
    padding: 10px 0;
    z-index: 99;
    margin-left: -20px;
    padding-left: 20px;
}
.full-search-results-title.sticky {
    position: sticky;
    top: 0;
    z-index: 30;
    background: inherit;
}
.full-search-results-subjects {
    @include flex-start;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    padding: 10px 0px;
    margin: 0 -8px;
    /* margin-bottom: -25px; */
}
.full-search-results .search-results-user {
    @include flex-start;
    align-items: center;
    width: fit-content;
    height: fit-content;
    background-color: #f5f5f5;
    column-gap: 8px;
    row-gap: 8px;
    padding: 8px 10px;
    border-radius: $button-border-radius;
    color: $secondary-font-color;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        background-color: #e5e5e5;
    }
}
.search-results-resource {
    height: fit-content;
    min-height: 45px;
    width: 100%;
    @include flex-start;
    align-items: center;
    column-gap: 8px;
    padding: 5px 5px;
    border-radius: $button-border-radius;
    color: $primary-font-color;
    font-weight: 400;
    cursor: pointer;
    &:hover {
        background-color: $grey-hover;
    }
}

.search-results-resource .resource-type-icon {
    padding: 0 !important;
    margin: 0;
    height: 18px;
    width: 18px;
}

.search-results-resource .icon .resource-type-icon{
    font-size: 16px;
    display: inline;
    height: 20px;
    padding-top: 3px;
}

.search-results-note-content {
    flex-grow: 1;    
    // width: 95%;
    @include multi-line-ellipsis($lines: 2);
}

/* Small search */
#navbar-search-small .search-icon{
    position: inherit;
    font-size: 24px;
    color: #a5a5a5;
    transform: none;
    cursor: pointer;
}
