.focus-mode {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 25vw;
    z-index: 9999; /* Ensures the element is above all others */
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;

    .focus-mode-header {
        width: 100vw;
        height: 70px;
        padding: 10px 25vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
    }

    @include respond-to(x-large) {
        padding: 0px 20vw;

        .focus-mode-header {
            padding: 10px 20vw;
        }
    }

    @include respond-to(large) {
        padding: 0px 10vw;

        .focus-mode-header {
            padding: 10px 10vw;
        }
    }

    @include respond-to(medium) {
        padding: 0px 20px;

        .focus-mode-header {
            height: 60px;
            padding: 10px 20px;
        }
    }

    form {
        width: 100% !important;
        height: fit-content;
    }

    .link-resource-button {
        width: fit-content;
    }
    .cancel-or-submit {
        margin-top: 0px;
        width: fit-content;
    }


    /* Focus Mode Add Note Card */
    .add-note-card-header {
        height: fit-content;
        width: 100%;
        margin-bottom: 0px;
        margin-top: 10px;
        border: none;

        .add-note-title {
            height: fit-content;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.5;
            margin-bottom: 0px;
        }
        p {
            font-size: 18px;
            font-weight: 400;
        }
    }



    // Focus Mode RichEditor

    .RichEditor-root {
        width: 100%;
        height: fit-content;
        margin-bottom: 20px;
    }
    .RichEditor-editor {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        border: none;
    }
        .RichEditor-editor:focus {
            border: transparent;
        }
        @media screen and (max-width: 900px) {
            .RichEditor-editor {
                font-size: 16px;
            }
        }

    .RichEditor-editor .public-DraftEditor-content {
        height: fit-content;
        color: #333333;
        line-height: 150%;
        overflow: auto;
        max-height: none;
    }
    .RichEditor-control-bar {
        height: 45px;
        position: sticky;
        top: 0;
        background-color: #fff;
        padding: 10px 0;
        z-index: 99999;
        border-bottom: 1px solid #ddd;
    }
    .RichEditor-controls {
        font-size: 16px;
        margin-bottom: 5px;
        user-select: none;
        display: inline;

        .blockquote {
            font-size: 14px;
        }

        .RichEditor-styleButton {
            color: #999;
            cursor: pointer;
            margin-right: 20px;
            padding: 2px 0;
            display: inline-block;
        }
    }
    .RichEditor-controls.hyperlink {
        font-size: 17px;
    }

    .RichEditor-activeButton {
        color: #257953;
      }

    .add-hyperlink {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
    }
    .add-hyperlink button {
      border: none;
      background-color: transparent;
      color: #333333;
    }
}