.my-library{
    width: 100%;
    padding: 0 30px 0 50px;
    // @include respond-to(medium) {
    //     padding: 0 30px 0 30px; 
    // }
    @include respond-to(small) {
        padding: 0 10px; 
    }
}

.my-library-header {
    @include flex-start;
    @include flex-Vcenter;
    padding: 8px;
    font-size: $primary-title-size;
    font-weight: 500;
    column-gap: 10px;
    color: $secondary-font-color;
}

.library-tabs-wrapper {
    @include flex-space-between;
    @include flex-Vcenter;
    width: 100%;
    padding: 10px 0;
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 99;
    transition: top 0.3s;
    margin-bottom: 10px;

    @include respond-to(small) {
        width: 100vw;
        margin-left: -20px;
        padding-left: 20px;
    }

    .tab-button {
        font-weight: 500;
        font-size: 18px;
        color: $tertiary-font-color;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            color: $secondary-font-color;
        }
    }
    .tab-button.active {
        color: $primary-color;
    }
}

.library-tabs {
    @include flex-column;
    width: 100%;
}

.library-tabs .tabs {
    height: fit-content;
    margin: 0;
}

.library-cards-container {
    @include flex-start;
    // justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 30px;
    padding: 0 10px;
    // @include respond-to(large) {
    //     @include flex-center;
    //     flex-direction: column;
    // }
    @include respond-to(small) {
        width: 100%;
        padding: 0px;
    }
}
