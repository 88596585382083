.modal-content.note-likes {
    width: 300px;
    height: fit-content;
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    @include flex-column;
    align-items: center;
    padding: 15px;
    row-gap: 0px;
}
    .note-likes .name-list {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
    }
.note-likers {
    width: 100%;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.note-likes .user-name {
    font-size: 16px;
    font-weight: 600;
    color: $secondary-font-color;
    margin-left: 10px;
    cursor: pointer;
}

.learned-user-count {
    width: 100%;
    height: 40px;
    @include flex-start;
    @include flex-Vcenter;
    font-size: 16px;
    font-weight: 500;
    color: $secondary-font-color;
}