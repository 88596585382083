@keyframes joggle {
  0% { transform: scale(1); }
  20% { transform: scale(1.75); }
  40% { transform: scale(1.25); }
  60% { transform: scale(1.1); }
  80% { transform: scale(1.25); }
  100% { transform: scale(1); }
}

.like-icon {
  transition: transform 0.2s ease-in-out;

  &.liked {
    &.animate-like {
      animation: joggle 0.4s ease-in-out;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}