.edit-cus-label-form {
    @include flex-start;
    @include flex-column; 
    width: 100%;
}

.edit-cus-label-form .cus-label-name {
    width: 100%;
    height: 28px;
    @include flex-start;
    font-size: 22px;
    font-weight: 800;
    color: $custom-color;
    border: none;
    outline: none;
    background-color: #fff;
    padding: 10px 0;
}

.edit-cus-label-form .cus-label-description {
    width: 100%;
    min-height: 100px;
    font-size: 14px;
    color: $secondary-font-color;
    border: none;
    outline: none;
    background-color: #fff ;
    padding: 10px 0;
    margin-bottom: 10px;
    resize: none;
}

.edit-cus-label-form .buttons {
    width: 100%;
    @include flex-end;
    column-gap: 10px;
}