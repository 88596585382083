.ag-landing-page {
    width: 100vw;
    margin-top: -70px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(135deg, #fff 20%, #effaf5 90%);
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-x: hidden;

    .hero-section, .features-section, .cta-section {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity 0.8s ease-out, transform 0.8s ease-out;

        &.visible {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .hero-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        padding: 80px 20px;
        margin: 50px 0;

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 60px 20px;
            margin: 30px 0;
        }
        @media screen and (max-width: 475px) {
            padding: 20px 20px;
            margin: 20px 0;
        }

        .hero-content {
            flex: 1;
            padding-right: 40px;

            @media (max-width: 768px) {
                padding-right: 0;
                text-align: center;
                margin-bottom: 40px;
            }

            .hero-title {
                font-size: 38px;
                font-weight: 700;
                color: #333333;
                margin-bottom: 20px;
                line-height: 1.2;

                .animated-text-wrapper {
                    display: inline-block;
                    position: relative;
                }

                .animated-text {
                    display: inline-block;
                    animation: fadeInOut 4s ease-in-out infinite;
                    font-weight: bold;
                    color: #257953;
                }

                @keyframes fadeInOut {
                    0%, 100% { opacity: 0; }
                    20%, 80% { opacity: 1; }
                }
            }

            .hero-subtitle {
                font-size: 18px;
                color: #555;
                margin-bottom: 30px;
                line-height: 1.4;
            }

            .cta-button {
                display: inline-flex;
                align-items: center;
                gap: 8px;
                padding: 12px 24px;
                border: none;
                border-radius: 5px;
                text-decoration: none;
                font-size: 1rem;
                font-weight: bold;
                transition: all 0.3s ease;
                background-color: #48c78e;
                color: #fff;

                &:hover {
                    background-color: #339966;
                    transform: translateY(-2px);
                }
            }
        }

        .hero-animation {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .subject-page {
                max-width: 100%;
                height: auto;
                border-radius: 10px;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            }

            .note-img {
                position: absolute;
                bottom: -30px;
                right: -50px;
                width: 60%;
                z-index: 2;
                border-radius: 10px;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            }

            @media (max-width: 768px) {
                .note-img {
                    bottom: -20px;
                    right: -20px;
                }
            }
        }
    }

    .features-section {
        width: 100%;
        // max-width: 1600px;
        padding: 80px 20px;
        margin: 50px auto;

        h2 {
            font-size: 28px;
            color: #257953;
            margin-bottom: 100px;
            text-align: center;
            font-weight: 600;
            @media screen and (max-width: 768px) {
                margin-bottom: 80px;
            }
        }

        .feature-grid {
            display: flex;
            flex-direction: column;
            gap: 150px;

            .feature-item {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 100px;

                &.reverse {
                    flex-direction: row-reverse;
                }

                .feature-content {
                    // flex: 1;
                    max-width: 480px;

                    .feature-icon {
                        font-size: 40px;
                        color: #48c78e;
                        margin-bottom: 20px;
                    }

                    h3 {
                        font-size: 24px;
                        color: #333;
                        margin-bottom: 15px;
                        font-weight: 600;
                    }

                    p {
                        font-size: 18px;
                        color: #555;
                        line-height: 1.6;
                    }
                }

                .feature-demo {
                    flex: 1;
                    position: relative;
                    overflow: hidden;
                    border-radius: 10px;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease, box-shadow 0.3s ease;

                    &:hover {
                        transform: translateY(-10px);
                        box-shadow: 0 15px 40px #48c78e70;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        display: block;
                        opacity: 1;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        pointer-events: none;
                    }

                    &.demo-one {
                        max-width: 500px;
                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                        }
                    }

                    &.demo-two {
                        max-width: 600px;
                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                        }
                    }

                    &.demo-three {
                        max-width: 440px;
                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                        }
                    }

                    &.demo-four {
                        max-width: 500px;
                        @media screen and (max-width: 768px) {
                            max-width: 100%;
                        }
                    }
                }

            }
        }

        @media (max-width: 768px) {
            .feature-grid {
                .feature-item {
                    gap: 80px;
                }
                .feature-item, .feature-item.reverse {
                    flex-direction: column;
                    text-align: center;

                    .feature-content, .feature-demo {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .cta-section {
        width: 100%;
        padding: 80px 20px;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        text-align: center;
        border-radius: 20px;
        margin: 50px 0;

        h2 {
            font-size: 28px;
            font-weight: 600;
            color: #257953;
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
            color: #333;
            margin-bottom: 40px;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

        .cta-button {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 15px 30px;
            border: none;
            border-radius: 5px;
            text-decoration: none;
            font-size: 1.1rem;
            font-weight: bold;
            transition: all 0.3s ease;
            background-color: #48c78e;
            color: #fff;

            &:hover {
                background-color: #339966;
                transform: translateY(-2px);
            }
        }
    }
}