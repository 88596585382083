.MoreTabWLink {
    color: $tertiary-font-color;
    flex-shrink: 0;
    width: 24px;
    height: 20px;
    cursor: pointer;
    position: relative;

    &:hover {
        color: $primary-color;
    }
}

.card-action-dropdown {
    width: fit-content;
    height: fit-content;
    @include flex-column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: -10px;
    background-color: #ffffff;
    border-radius: $button-border-radius;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 8px;
    z-index: 98;
}

.card-action-laid {
    width: fit-content;
    height: fit-content;
    @include flex-start;
    align-items: center;
    column-gap: 5px;
    margin-top: 10px;
}

.card-action-dropdown .card-action-group{
    height: 28px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: 5px;
}
.card-action-laid .card-action-group {
    height: 28px;
    width: fit-content;
    @include flex-start;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    padding: 5px;
}

.card-action-dropdown .card-action-group p,
.card-action-laid .card-action-group p
{
    color: $tertiary-font-color;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
}

.card-action-group .card-action-icon {
    color: $tertiary-font-color;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
}

.card-action-group:hover {
    background-color: #f3f3f3;
    border-radius: $button-border-radius;
}
.card-action-group.delete-group:hover p,
.card-action-group.delete-group:hover svg {
    color: #fb4242;
}

.card-action-icon.delete-icon {
    font-size: 18px;
}

.card-action-icon.delete-icon:hover {
    color: #fb4242;
}
.card-action-icon.share{
    margin-left: 2px;
    font-size: 19px;
}
