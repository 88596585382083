.resource-page-resource {
    @include flex-column;
    width: fit-content;
    padding-top: 10px;

    @include respond-to(large) {
        margin-left: 5px;
    }
    @include respond-to(medium) {
        margin-top: 10px;
    }
    @include respond-to(small) {
        width: 100%;
        padding: 0px 10px 0px 15px;
        margin-left: 0px;
    }
}

.resource-page-resource #resource-card {
    margin-bottom: 15px;
    margin-left: 5px;
    @include respond-to(small) {
        margin-left: 0px;
    }
}

.resource-page-resource #resource-card .resource-card-upper-right {
    @include respond-to(small) {
        flex: 1;
    }
}

.resource-page-resource #resource-card .resource-text-info {
    row-gap: 15px;
    @include respond-to(small) {
        width: 100%;
    }
}

.resource-page-resource #resource-card .the-img {
    height: 100%;
}

.resource-page-resource .add-note-card-button {
    padding-left: 0px;
    @include respond-to(small) {
        padding: 10px 0px;
    }
}

.learned-users {
    width: 100%;
    display: inline;
    font-size: 14px;
    font-weight: 500;
    color: $tertiary-font-color;
    margin-top: 10px;
}

.learned-users span {
    display: inline;
}

.learned-users span::after {
    content: " ";
}