.whole-screen-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    z-index: 666;
    overflow: hidden;
}

.modal-header {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.whole-page-modal-content {
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

@include respond-to (medium) {
    .whole-screen-modal {
        padding-top: 40px;
    }
}