.username-dropdown {
    height: fit-content;
    padding: 5px 0px;
    display: flex;
    position: relative;
}

.navbar-user-name {
    height: 35px;
}
    .username-dropdown .notification-badge {
        height: 10px;
        width: 10px;
        position: absolute;
        top: 2px;
        right: -2px;
        background-color: $badge-color;
        border-radius: 50%;
    }
    .username-dropdown .notification-count {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #eeeeee;
        color: #636363;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.name-dropdown-menu {
    height: fit-content;
    width: fit-content;
    min-width: 180px;
    padding: 8px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border-radius: $button-border-radius;
    box-shadow: $card-box-shadow;
    z-index: 20;
}

.dropdown-item.setting{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    &.sidebar-toggle {
        height: 50px;
        p {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
        }
        span {
            font-size: 10px;
        }
    }
}
    .dropdown-item.setting p {
        color: $secondary-font-color;
    }
    .dropdown-item.setting .notification-count {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #eeeeee;
        color: #636363;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dropdown-item.setting:hover {
        background-color: #f3f3f3;
        border-radius: $button-border-radius;
    }

.notification-count {
    height: 16px;
    width: 16px;
}
.dropdown-item.setting:hover .notification-count {
    background-color: #ffffff;
    color: #636363;
}

.logout {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 400;
    color: $secondary-font-color;
    cursor: pointer;
}
.logout:hover {
    background-color: #f3f3f3;
    border-radius: $button-border-radius;
    color: #fb4242;
}