/* purgecss start ignore */
@import './_variables';

button {
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.button-span {
    @include flex-center;
    @include flex-Hcenter;
    column-gap: 5px;
}

.linko-button {
    @include flex-center;
    height: 30px;
    padding: 5px 10px;
    border-radius: $button-border-radius;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    column-gap: 5px;

    &--primary {
        background-color: $grey-background;
        color: $secondary-font-color;
    }

    &--secondary {
        background-color: $tertiary-color;
        color: $secondary-color;
    }

    &--tertiary {
        background-color: white;
        color: $primary-color;
        border: 1px solid $primary-color;
    }
    &--tertiary-outline {
        background-color: white;
        color: $tertiary-font-color;
        border: 1px solid #ccc;
        cursor: not-allowed;
        pointer-events: none;
    }

    &--cancel {
        background-color: white;
        color: $tertiary-font-color;
        &:hover {
            background-color: #f3f3f3;
        }
    }

    &--danger {
        background-color: white;
        color: $tertiary-font-color;
        &:hover {
            background-color: #f3f3f3;
            color: #fb4242;
        }
    }
}

// .linko-button .button-text {
//     @include respond-to(medium) {
//         display: none;
//     }
// }


.back-button-wrapper {
    position: absolute;
    top: 5px;
    left: -60px;
    background-color: transparent;

    @include respond-to(x-large) {
        left: -40px;
    }
    @include respond-to(large) {
        width: 100%;
        margin-right: auto;
        margin-bottom: 20px;
        left: 10px;
        position: inherit;
        width: fit-content;
        @include flex-start;
    }
    @include respond-to(large) {
        margin-left: -20px;
    }
    @include respond-to(small) {
        margin-left: 0px;
        left: 10px;
    }
}
.back-button-wrapper .back-button {
    padding: 8px;
    background-color: transparent;
}

.show-button {
    font-size: 13px;
    color: $tertiary-font-color;
    margin: 0;
    width: fit-content;
    height: 26px;
    white-space: nowrap;
    @include flex-Hcenter;
    align-items: center;
    &:hover {
        color: $primary-color;
    }
}
/* purgecss end ignore */