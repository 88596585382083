.subject-tag {
    width: fit-content;
    height: 26px;
    font-size: 13px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 13px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}
    .subject-tag a{
        font-size: 13px;
        color: #535353;
    }

.studied {
    background-color: #effaf5;
    color: #257953;
    &:hover {
        background-color: #d9f5e5;
    }
}
    .studied a{
        font-size: 13px;
        color: #257953 !important;
    }

.subject-tag-learn-count {
    height: 20px;
    min-width: 20px;
    width: fit-content;
    padding: 0 3px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    background-color: #fff;
    border-radius: 10px;
} 

.not-studied {
    background-color: #f3f3f3;
    color: $secondary-font-color;
    &:hover {
        background-color: #e5e5e5;
    }
}

.sibling-field {
    border: 0.5px solid #666666;
    color: #666666;
    &:hover {
        background-color: $grey-hover;
    }
}

.cus-label {
    @include flex-center;
    height: 26px;
    width: fit-content;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 5px;
    background: #FFF6DE;
    cursor: pointer;
    position: relative;
    color: $custom-color;
    white-space: nowrap;
}
    .cus-label:hover {
        background-color: #fff0c5;
    }

    .cus-label.selected {
        background-color: $custom-color;
        color: #FFF6DE;
    } 
    /* .cus-label:hover p{
        color: #FFF6DE;
    }
    .cus-label:hover a{
        color: #FFF6DE !important;
    } */

    .cus-label a{
        font-size: 13px;
        color: $custom-color !important;
    }
    .cus-label p{
        font-size: 13px;
        color: $custom-color;
    }


/* Note Card Specifics */
.subject-tag .delete-subject-icon {
    font-size: 22px;
    margin-left: 1px;
    cursor: pointer;
    visibility: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    box-sizing: border-box;
    color: #bbbbbb;
}
/* .subject-tag .delete-subject-icon {
    color: #effaef;
}
.subject-tag .cus-label .delete-subject-icon {
    color: #FFF6DE;
} */
.delete-subject-icon:hover {
    color: #ba2525;
}
.note-card-subjects .subject-tag:hover .delete-subject-icon{
    visibility: visible;
}
.note-card-subjects .cus-label:hover .delete-subject-icon{
visibility: visible;
}
.cus-label .delete-subject-icon:hover {
    color: #ba2525;
}