.tippy-box[data-theme~='light-border'] {
    background-color: #fff;
    color: #636363;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: $tooltip-border-radius;
    z-index: 99999999;
}
    .tippy-box[data-theme='light-border'][data-placement^='top'] > .tippy-arrow::before {
        border-top-color:  #fff;
    }
    .tippy-box[data-theme='light-border'][data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color:  #fff;
        z-index: 99999999;
    }
    .tippy-box[data-theme='light-border'][data-placement^='left'] > .tippy-arrow::before {
        border-left-color:  #fff;
    }
    .tippy-box[data-theme='light-border'][data-placement^='right'] > .tippy-arrow::before {
        border-right-color: #fff;
    }


.tippy-box[data-theme~='heavy-shadow'] {
    background-color: #fff;
    color: #636363;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: $tooltip-border-radius;
    z-index: 99999999;
} 
    .tippy-box[data-theme='heavy-shadow'][data-placement^='top'] > .tippy-arrow::before {
        border-top-color:  #fff;
    }
    .tippy-box[data-theme='heavy-shadow'][data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color:  #fff;
        z-index: 99999999;
    }
    .tippy-box[data-theme='heavy-shadow'][data-placement^='left'] > .tippy-arrow::before {
        border-left-color:  #fff;
    }
    .tippy-box[data-theme='heavy-shadow'][data-placement^='right'] > .tippy-arrow::before {
        border-right-color: #fff;
    }



// Heatmap tooltip 
#heatmapTooltip {
    z-index: 9999 !important;
    color: black !important;
    background-color: white !important;
    border: 1px solid #ccc !important;
    padding: 5px !important;
}