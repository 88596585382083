
  // Filter 
  .library-subject-filter {
    width: 100%;
    @include flex-Vcenter;
    flex-wrap: wrap;
    column-gap: 5px;
    margin-bottom: 15px;
    row-gap: 8px;
    height: fit-content;
    
}

.library-subject-filter .cus-label.inactive {
    background-color: #f3f3f3;
    color: $secondary-font-color;
}

.library-subject-filter .cus-label.active {
    background-color: #FFF6DE;
    color: $custom-color;
}

.resource-tab-filter {
    @include flex-start;
    align-items: center;
    column-gap: 20px;
    width: fit-content;
    height: 40px;
    background-color: #fff;
    margin-bottom: 10px;
    overflow: auto;
    @media screen and (max-width: 1148px) {
        flex-direction: column;
        height: fit-content;
        align-items: flex-start;
        row-gap: 5px;
        margin-bottom: 15px;
    }
}

.filter-button-wrapper {
    margin-left: auto;
    margin-right: 10px;
    @include respond-to(small) {
        margin-right: 20px;
    }
}


.library-filter p {
    font-size: 14px;
}

.filter-labels {
    @include flex-Vcenter;
    height: 30px;
    width: fit-content;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
        height: fit-content;
        flex-wrap: wrap;
        justify-content: flex-start;
        row-gap: 5px;
    }
}

.type-filter-button {
    @include flex-center;
    column-gap: 5px;
    height: 28px;
    width: fit-content;
    border: none;
    border-radius: $button-border-radius;
    padding: 3px 5px;
    background-color: #fff;
    color: $secondary-font-color;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 3px;
    white-space: nowrap;
}
    .type-filter-button:hover {
        background-color: #f0f0f0;
    }

.active-filter {
    background-color: $tertiary-color;
    color: $primary-color;
  }
  .active-filter:hover {
    background-color: $tertiary-color;
    color: $primary-color;
  }




