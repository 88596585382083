/* purgecss start ignore */
@import './_variables';

// Card
.linko-card {
    @include flex-center;
      width: 620px;
      height: fit-content;
      background-color: white;
      border-radius: $card-border-radius;
      border: 2px solid #f2f3f4;
      padding: 20px;
      margin-bottom: 5px;
      @include respond-to(small) {
        width: 100%;
      }
    }



/* purgecss end ignore */