.subject-page-header {
    width: 620px;
    height: fit-content !important;
    @include flex-center;
    white-space: normal;
    flex-direction: column;
    column-gap: 10px;
    height: fit-content;
    padding: 5px 20px;
    z-index: 97;
    margin-top: 10px;
    border-radius: 10px;
    @include respond-to (large) {
        flex: 1;
        padding: 10px 20px;
        flex-direction: column;
    }
    @include respond-to (small) {
        margin: 0 10px;
        width: calc(100% - 20px);
    }

    h1 {
        font-size: 18px;
        font-weight: 600;
    }
    span {
        font-size: 14px;
        font-weight: 400;
    }
}

.subject-name {
    // width: fit-content;
    font-size: $primary-title-size;
    font-weight: 600;
    display: inline;
    white-space: normal;
    @include respond-to (medium) {
        font-size: $secondary-title-size;
    }
}

.overview-button {
    width: fit-content;
    height: fit-content;
    // padding: 2px 5px;
    border-radius: $button-border-radius;
    background-color: $secondary-color;
    color: $tertiary-color;
}

.subject-description {
    // width: 100%;
    @include flex-center;
    // gap: 10px;
    color: $secondary-font-color;
    font-size: $primary-text-size;
}

.subject-learn-count {
    @include flex-start;
    flex-wrap: wrap;
    column-gap: 5px;
    font-size: 14px;
    font-weight: 500;
    color: $secondary-font-color;
    margin-bottom: 5px;
}

.subject-rel .description {
    width: 100%;
    height: fit-content;
    color: $secondary-font-color;
    font-size: 14px;
    white-space: normal; 
    margin-bottom: 10px;
}