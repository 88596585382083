
.note-card-footer {
    @include flex-space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 8px 15px 8px 20px;
    border-radius: $card-border-radius;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    cursor: pointer;
}
    .note-card-footer:hover {
        background-color: $grey-hover;
    }
@media (max-width: 670px) {
    .note-card-footer {
        width: 100%;
        padding: 1% 2%;
    }
}
// .note-page-card-footer {
//     @include flex-column;
//     align-items: flex-start;
//     height: fit-content;
//     width: 100%;
//     row-gap: 8px;
//     padding: 8px 15px 8px 25px;
//     border-radius: 0px 0px 24px 24px;
//     cursor: pointer;
//     &:hover {
//         background-color: $grey-hover;
//     }
//     @include respond-to(small){
//         padding: 1% 2%;
//     }
// }

    .note-page-card-footer .note-card-resource-author {
        margin-left: 30px;
    }

.note-card-resource {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    flex-grow: 1;
}
@media (max-width: 670px) {
    .note-card-resource {
        width: 100%;
        padding: 0px;
    }
}

.note-card-resource .resource-type-icon {
    flex-shrink: 0;
    overflow: hidden;
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-right: 3px;
}
@media (max-width: 670px) {
    .note-card-resource .icon {
        display: none;
    }
}

.note-card-resource-title {
    height: 24px;
    margin-left: 5px;
    max-width: 100%;
    font-size: 16px;
    font-weight: 100;
    color: #636363;
    font-weight: 500;
    overflow: hidden;
}
@media (max-width: 670px) {
    .note-card-resource-title {
        width: 100%;
    }
}

.note-card-resource-author {
    color: var(--basic-black-80, #636363);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-align: right;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 670px) {
    .note-card-resource-author {
        display: none;
    }
}